<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LA LISTA DE PASAJERO:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listPax.length}}</span>
                                <br>
                                <span class="text-muted">Pasajeros Registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Pasajeros</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" @click="modalRegPax = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Pasajero</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>

            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista de Pasajeros Registrados </span>
                        <b-button v-if="listPax.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listPax" :fields="camposListPax" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(guiaPrincipal)="data">
                                        <template v-if="listUsuarios.find(x => x.idGuia == data.item.idGuia) == null">
                                            {{''}}
                                        </template>
                                        <template v-else>
                                            {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}}
                                        </template>
                                    </template>
                                    <template v-slot:cell(nombrePasajero)="data">
                                        {{data.item.nombrePasajero | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(nroDocumento)="data">
                                        {{data.item.numeroDocumento | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(nacionalidad)="data">
                                        {{data.item.nacionalidad | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActPax(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarPax(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalRegPax" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Nuevo Pasajero</span>
                </h6>
                <CButtonClose @click="modalRegPax = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarPax)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guía Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegPax.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRegPax.idGuia" :options="listUsuarios" @search:blur="blurRegPax">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre pasajero" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre Pasajero:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre del Pasajero" v-model.trim="datosRegPax.nombrePasajero"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nacionalidad" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nacionalidad:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Nacionalidad" v-model.trim="datosRegPax.nacionalidad"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo documento" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Documento:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listDocumento => listDocumento.id" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegPax.tipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosRegPax.tipoDocumento" :options="listDocumento" @search:blur="blurRegDocumento">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosRegPax.numeroDocumento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="edad" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Edad:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Edad" v-model.trim="datosRegPax.edad"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="tipo genero" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Género:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listGenero => listGenero.id" label="genero" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegPax.genero , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosRegPax.genero" :options="listGenero" @search:blur="blurRegGenero">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="tipo condicion" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Condición:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listCondicion => listCondicion.id" label="condicion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegPax.condicion , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosRegPax.condicion" :options="listCondicion" @search:blur="blurRegCondicion">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="detalle" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Detalle:" class="mb-2">
                                    <b-form-textarea v-model.trim="datosRegPax.detalle" :state="getValidationState(validationContext)" placeholder="Ingrese el Detalle" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observacion" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Observación(Opcional):" class="mb-2">
                                    <b-form-textarea v-model.trim="datosRegPax.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese el Observación" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegPax = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActPax" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Actualizar Pasajero</span>
                </h6>
                <CButtonClose @click="modalActPax = false" class="text-white" />
            </template>
            <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarPax)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guía Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActPax.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActPax.idGuia" :options="listUsuarios" @search:blur="blurActPax">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre pasajero" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre Pasajero:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre del Pasajero" v-model.trim="datosActPax.nombrePasajero"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nacionalidad" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nacionalidad:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Nacionalidad" v-model.trim="datosActPax.nacionalidad"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo documento" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo Documento:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listDocumento => listDocumento.id" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActPax.tipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActPax.tipoDocumento" :options="listDocumento" @search:blur="blurActDocumento">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nro. documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nro. Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosActPax.numeroDocumento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="edad" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Edad:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Edad" v-model.trim="datosActPax.edad"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="tipo genero" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Género:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listGenero => listGenero.id" label="genero" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActPax.genero , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActPax.genero" :options="listGenero" @search:blur="blurActGenero">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="4">
                            <validation-provider name="tipo condicion" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Condición:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listCondicion => listCondicion.id" label="condicion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActPax.condicion , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActPax.condicion" :options="listCondicion" @search:blur="blurActCondicion">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="detalle" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Detalle:" class="mb-2">
                                    <b-form-textarea v-model.trim="datosActPax.detalle" :state="getValidationState(validationContext)" placeholder="Ingrese el Detalle" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="observacion" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Observación:" class="mb-2">
                                    <b-form-textarea v-model.trim="datosActPax.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese el Observacion" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button variant="danger" @click="modalActPax = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbCondicion = firebase.firestore().collection('tipoCondicion');
const dbDocumento = firebase.firestore().collection('tipoDocumento');
const dbGenero = firebase.firestore().collection('tipoGenero');

const dbListPax = firebase.firestore().collection('listPax');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            listPax: [],
            listUsuarios: [],
            listGenero: [],
            listCondicion: [],
            listDocumento: [],
            disabled: false,

            camposListPax: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "guiaPrincipal",
                    label: "Guia Principal",
                    class: "text-center",
                },
                {
                    key: "nombrePasajero",
                    label: "Nombre Pasajero",
                    class: "text-center",
                },
                {
                    key: "nroDocumento",
                    label: "Nro. Documento",
                    class: "text-center",
                },
                {
                    key: "nacionalidad",
                    label: "Nacionalidad",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            datosRegPax: {
                idGuia: '',
                nombrePasajero: '',
                nacionalidad: '',
                tipoDocumento: '',
                numeroDocumento: '',
                edad: '',
                genero: '',
                condicion: '',
                detalle: '',
                observacion: '',
            },

            datosActPax: {
                id: '',
                idGuia: '',
                nombrePasajero: '',
                nacionalidad: '',
                tipoDocumento: '',
                numeroDocumento: '',
                edad: '',
                genero: '',
                condicion: '',
                detalle: '',
                observacion: '',
            },

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],
            
            modalRegPax: false,
            modalActPax: false,
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listPax.map(data => {
                return {
                    A: valUpper(this.listUsuarios && this.listUsuarios !== undefined ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia).length > 0 ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia)[0].nombre : '' : ''),
                    B: valUpper(data['nombrePasajero']),
                    C: valUpper(data['nacionalidad']),
                    D: valUpper(this.listDocumento && this.listDocumento !== undefined ?
                        this.listDocumento.filter(x => x.id == data.tipoDocumento).length > 0 ?
                        this.listDocumento.filter(x => x.id == data.tipoDocumento)[0].nombre : '' : ''),
                    E: valUpper(data['numeroDocumento']),
                    F: valUpper(data['edad']),
                    G: valUpper(this.listGenero && this.listGenero !== undefined ?
                        this.listGenero.filter(x => x.id == data.genero).length > 0 ?
                        this.listGenero.filter(x => x.id == data.genero)[0].genero : '' : ''),
                    H: valUpper(this.listCondicion && this.listCondicion !== undefined ?
                        this.listCondicion.filter(x => x.id == data.condicion).length > 0 ?
                        this.listCondicion.filter(x => x.id == data.condicion)[0].condicion : '' : ''),
                    I: valUpper(data['detalle']),
                    J: valUpper(data['observacion']),
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 30, //B
                },
                {
                    wch: 15, //C
                },
                {
                    wch: 50, //D
                },
                {
                    wch: 20, //E
                },
                {
                    wch: 15, //F
                },
                {
                    wch: 30, //G
                },
                {
                    wch: 30, //H
                },
                {
                    wch: 50, //I
                },
                {
                    wch: 50, //J
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'LISTADO DE PASAJEROS',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE DE GUÍA',
                },
                {
                    f: 'B4',
                    v: 'NOMBRE DEL PASAJERO',
                },
                {
                    f: 'C4',
                    v: 'NACIONALIDAD',
                },
                {
                    f: 'D4',
                    v: 'DOCUMENTO',
                },
                {
                    f: 'E4',
                    v: 'NRO. DOCUMENTO',
                },
                {
                    f: 'F4',
                    v: 'EDAD',
                },
                {
                    f: 'G4',
                    v: 'GÉNERO',
                },
                {
                    f: 'H4',
                    v: 'CONDICIÓN',
                },
                {
                    f: 'I4',
                    v: 'DETALLE',
                },
                {
                    f: 'J4',
                    v: 'OBSERVACIÓN',
                },
            ]) {
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'LISTADO DE PASAJEROS');
            XLSX.writeFile(wb, `LISTADO DE PASAJEROS ${now}.xlsx`);
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length;
            this.paginaActual = 1;
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurRegPax() {
            this.computedForms.refs['guia principal'].validate();
        },
        blurActPax() {
            this.computedFormActualizar.refs['guia principal'].validate();
        },
        blurRegDocumento() {
            this.computedForms.refs['tipo documento'].validate();
        },
        blurActDocumento() {
            this.computedFormActualizar.refs['tipo documento'].validate();
        },
        blurRegGenero() {
            this.computedForms.refs['tipo genero'].validate();
        },
        blurActGenero() {
            this.computedFormActualizar.refs['tipo genero'].validate();
        },
        blurRegCondicion() {
            this.computedForms.refs['tipo condicion'].validate();
        },
        blurActCondicion() {
            this.computedFormActualizar.refs['tipo condicion'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        listarGenero() {
            let me = this;

            dbGenero
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listGenero = [];
                querySnapshot.forEach((doc) => {
                    me.listGenero.push({
                        id: doc.id,
                        genero: doc.data().genero,
                    });
                });
            });
        },
        listarDocumento() {
            let me = this;

            dbDocumento
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listDocumento = [];
                querySnapshot.forEach((doc) => {
                    me.listDocumento.push({
                        id: doc.id,
                        nombre: doc.data().nombre,
                    });
                });
            });
        },
        listarCondicion() {
            let me = this;

            dbCondicion
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listCondicion = [];
                querySnapshot.forEach((doc) => {
                    me.listCondicion.push({
                        id: doc.id,
                        condicion: doc.data().condicion,
                    });
                });
            });
        },
        registrarPax(){
            let me = this;
            me.disabled = true;
            dbListPax.add({
                idGuia: me.datosRegPax.idGuia.trim(),
                nombrePasajero: me.datosRegPax.nombrePasajero.trim(),
                nacionalidad: me.datosRegPax.nacionalidad.trim(),
                tipoDocumento: me.datosRegPax.tipoDocumento.trim(),
                numeroDocumento: me.datosRegPax.numeroDocumento.trim(),
                edad: me.datosRegPax.edad.trim(),
                genero: me.datosRegPax.genero.trim(),
                condicion: me.datosRegPax.condicion.trim(),
                detalle: me.datosRegPax.detalle.trim(),
                observacion: me.datosRegPax.observacion.trim(),
                estado: 2,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaModificacion: '',
                fechaEliminar: '',
                idCliente: me.$store.state.user.idCliente.trim(),
            }).then((doc) => {
                me.swat('success', 'Registrado satisfactoriamente')
                me.modalRegPax = false;
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        listarPax() {
            let me = this;

            dbListPax
            .where("estado","==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listPax = [];
                querySnapshot.forEach((doc) => {
                    me.listPax.push({
                        id: doc.id,
                        idGuia: doc.data().idGuia,
                        nombrePasajero: doc.data().nombrePasajero,
                        nacionalidad: doc.data().nacionalidad,
                        tipoDocumento: doc.data().tipoDocumento,
                        numeroDocumento: doc.data().numeroDocumento,
                        edad: doc.data().edad,
                        genero: doc.data().genero,
                        condicion: doc.data().condicion,
                        detalle: doc.data().detalle,
                        observacion: doc.data().observacion,
                        fechaCreacion: doc.data().fechaCreacion,
                    });
                });

                me.listPax.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarPax(param) {
            let me = this;

            me.$swal.fire({
                title: '¿Estas seguro de eliminar el Reporte?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbListPax.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disabled = false;
                    })
                    .catch((error) => {
                        me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });
                }
            });
        },
        abrirModalActPax(param) {
            let me = this;

            me.datosActPax.id = param.item.id;
            me.datosActPax.idGuia = param.item.idGuia.trim();
            me.datosActPax.nombrePasajero = param.item.nombrePasajero.trim();
            me.datosActPax.nacionalidad = param.item.nacionalidad.trim();
            me.datosActPax.tipoDocumento = param.item.tipoDocumento.trim();
            me.datosActPax.numeroDocumento = param.item.numeroDocumento.trim();
            me.datosActPax.edad = param.item.edad.trim();
            me.datosActPax.genero = param.item.genero.trim();
            me.datosActPax.condicion = param.item.condicion.trim();
            me.datosActPax.detalle = param.item.detalle.trim();
            me.datosActPax.observacion = param.item.observacion.trim();

            me.modalActPax = true;
        },
        actualizarPax() {
            let me = this;

            me.disabled = true;

            dbListPax.doc(me.datosActPax.id).update({
                idGuia: me.datosActPax.idGuia.trim(),
                nombrePasajero: me.datosActPax.nombrePasajero.trim(),
                nacionalidad: me.datosActPax.nacionalidad.trim(),
                tipoDocumento: me.datosActPax.tipoDocumento.trim(),
                numeroDocumento: me.datosActPax.numeroDocumento.trim(),
                edad: me.datosActPax.edad.trim(),
                genero: me.datosActPax.genero.trim(),
                condicion: me.datosActPax.condicion.trim(),
                detalle: me.datosActPax.detalle.trim(),
                observacion: me.datosActPax.observacion.trim(),
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then((doc)=> {
                me.swat('success', 'Modificado satisfactoriamente');
                me.modalActPax = false;
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegPax() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosRegPax.idGuia = '';
            this.datosRegPax.nombrePasajero = '';
            this.datosRegPax.nacionalidad = '';
            this.datosRegPax.tipoDocumento = '';
            this.datosRegPax.numeroDocumento = '';
            this.datosRegPax.edad = '';
            this.datosRegPax.genero = '';
            this.datosRegPax.condicion = '';
            this.datosRegPax.detalle = '';
            this.datosRegPax.observacion = '';
        },
        resetModalActPax() {
            this.$nextTick(() => {
                this.$refs.observer1.reset();
            });

            this.datosActPax.id = '';
            this.datosActPax.idGuia = '';
            this.datosActPax.nombrePasajero = '';
            this.datosActPax.nacionalidad = '';
            this.datosActPax.tipoDocumento = '';
            this.datosActPax.numeroDocumento = '';
            this.datosActPax.edad = '';
            this.datosActPax.genero = '';
            this.datosActPax.condicion = '';
            this.datosActPax.detalle = '';
            this.datosActPax.observacion = '';
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer1;
        },
    },
    watch: {
        modalRegPax: function (val) {
            if (val == false) {
                this.resetModalRegPax();
            }
        },
        modalActPax: function (val) {
            if (val == false) {
                this.resetModalActPax();
            }
        },
    },
    mounted(){
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarDocumento();
            this.listarGenero();
            this.listarCondicion();
            this.listarPax();
        }
    }
}
</script>